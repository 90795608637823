.App {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    text-align: center;
    background: linear-gradient(to right, #FF0000 5%, #F00303 10%, #E00505 20%, #D10808 30%, #C10B0B 40%, #B20D0D 50%, #A21010 60%, #931313 70%, #831515 80%, #741818 90%, #FF0000 95%);
    background-size: 200% auto;
    user-select: none;

    color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: shine 3s linear infinite;
}

@keyframes shine {
    to {
        background-position: 200% center;
    }
}

.price-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

h1 {
    margin: 0;
}

.full-wh {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 100%;
}

.bg-animation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.bg-animation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}

@keyframes offset {
    0% {
        stroke-dashoffset: 1060;
    }
    40% {
        stroke-dashoffset: 0;
    }
    60% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: 1060;
    }
}

.line {
    fill: none;
    stroke: #ffffff;
    stroke-width: 2px;
    stroke-dasharray: 1060;
    stroke-dashoffset: 1060;
    animation: offset 5s backwards infinite;
}

.discord:hover .line {
    fill: #ffffff;
}

a {
    z-index: 1;
}